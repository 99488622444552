export const GET_STOREFRONT_HOME = 'getStorefrontHome';
export const GET_CONTAINER_SELECTION = 'getContainerSelection';
export const CREATE_ORDER = 'createOder';

export const INIT_AUTH = 'initAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const SIGNUP_CUSTOMER = 'signupCustomer';

export const INIT_USER = 'initUser';
export const GET_USER = 'getUser';

export const REQUEST_PASSWORD_RESET = 'requestPasswordReset';
export const RESET_PASSWORD = 'resetPassword';
export const INIT_USER_INTERFACE = 'initUserInterface';

export const GET_ADDRESSES = 'getAddresses';
export const GET_ADDRESS = 'getAddress';
export const CREATE_ADDRESS = 'createAddress';
export const UPDATE_ADDRESS = 'updateAddress';
export const DELETE_ADDRESS = 'deleteAddress';

export const SEARCH_POSTAL_CODES = 'searchPostalCodes';

export const GET_ORDERS = 'getOrders';

export const CANCEL_MY_ORDERS = 'cancelMyOrders';
