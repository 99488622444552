import type { Module } from 'vuex';
import { authentication } from '@contimo/api/src/api';
import type { TSignupData } from '@contimo/api/src/api/authentication';
import { updateClientAuthToken } from '@contimo/api/src/api/client';
import type { TRootStore } from '@/store';
import { TOKEN_STORE } from '@/contants/api';
import {
  CANCEL_MY_ORDERS,
  INIT_AUTH,
  INIT_USER,
  LOGIN,
  LOGOUT,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  SIGNUP_CUSTOMER,
} from '../actionTypes';
import {
  SET_AUTH_LOADING,
  SET_NEXT_ROUTE,
  SET_TOKEN,
  SET_AUTH_READY,
  SET_LOGIN_LOADING,
} from '../mutationTypes';
import {
  ACCESS_TOKEN,
  AUTH_IS_READY,
  HAS_LOGIN_ERROR,
  IS_AUTH_LOADING,
  IS_LOGIN_LOADING,
  ROUTE_AFTER_LOGIN,
} from '../gettersTypes';

export interface IAuthStoreState {
  ready: boolean;
  loading: boolean;
  loginLoading: boolean;
  loginError: boolean;
  token: string | null;
  nextRoute: string | null;
  savedLoginEmail: string | null;
}

type TAuthStore = Module<IAuthStoreState, TRootStore>;

const authStore: TAuthStore = {
  state: () => ({
    ready: false,
    loading: false,
    loginLoading: false,
    loginError: false,
    token: null,
    nextRoute: null,
    savedLoginEmail: null,
  }),

  getters: {
    [IS_AUTH_LOADING]: (state) => state.loading,
    [IS_LOGIN_LOADING]: (state) => state.loginLoading,
    [HAS_LOGIN_ERROR]: (state) => state.loginError,
    [AUTH_IS_READY]: (state) => state.ready,
    [ROUTE_AFTER_LOGIN]: (state) => state.nextRoute,
    [ACCESS_TOKEN]: (state) => state.token,
  },

  mutations: {
    [SET_TOKEN](state, token: string | null): void {
      if (token) {
        localStorage.setItem(TOKEN_STORE, token);
      } else {
        localStorage.removeItem(TOKEN_STORE);
      }
      updateClientAuthToken(token);
      state.token = token;
    },
    [SET_NEXT_ROUTE](state, route: string) {
      state.nextRoute = route;
    },
    [SET_AUTH_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_LOGIN_LOADING](state, loading: boolean) {
      state.loginLoading = loading;
    },
    [SET_AUTH_READY](state, ready: boolean) {
      state.ready = ready;
    },
  },

  actions: {
    async [INIT_AUTH]({ commit, dispatch }, fullPath: string) {
      commit(SET_AUTH_READY, false);
      commit(SET_AUTH_LOADING, true);
      const token = localStorage.getItem(TOKEN_STORE);
      if (token) {
        commit(SET_TOKEN, token);
        try {
          const { data } = await authentication.relogin();
          commit(SET_TOKEN, data.auth.token);
          await Promise.all([dispatch(INIT_USER, data.user), dispatch(CANCEL_MY_ORDERS, fullPath)]);
        } catch (e) {
          commit(SET_TOKEN, null);
        }
      }
      commit(SET_AUTH_READY, true);
      commit(SET_AUTH_LOADING, false);
    },
    async [LOGIN]({ commit, dispatch }, { email, password }) {
      commit(SET_LOGIN_LOADING, true);
      try {
        const { data } = await authentication.login(email, password);
        if (!data.user.customerId) {
          throw new Error('User is not a customer');
        }

        commit(SET_TOKEN, data.auth.token);
        await dispatch(INIT_USER, data.user);
        commit(SET_LOGIN_LOADING, false);

        return true;
      } catch (e) {
        commit(SET_LOGIN_LOADING, false);
        throw e;
      }
    },
    async [LOGOUT]({ commit }, func?: () => void) {
      commit(SET_NEXT_ROUTE, null);
      try {
        await authentication.logout();
        commit(SET_TOKEN, null);
      } catch (e) {
        commit(SET_TOKEN, null);
      }
      if (func) func();
      return true;
    },
    async [REQUEST_PASSWORD_RESET](_, email: string) {
      await authentication.requestPasswordReset(email);
    },
    async [RESET_PASSWORD](
      _,
      { email, signature, password }: { email: string; signature: string; password: string },
    ) {
      await authentication.resetPassword(email, signature, password);
    },
    async [SIGNUP_CUSTOMER](_, signupData: TSignupData) {
      await authentication.signup(signupData);
    },
  },
};

export default authStore;
