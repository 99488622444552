import type { Module } from 'vuex';
import { users } from '@contimo/api/src/api';
import type { TRootStore } from '@/store';
import { INIT_USER, GET_USER } from '../actionTypes';
import { SET_USER } from '../mutationTypes';
import { THIS_USER } from '../gettersTypes';

export interface IUserStoreState {
  user: null | {
    manager: any;
    customer: any;
    isAdmin: boolean;
  };
}

type TUserStore = Module<IUserStoreState, TRootStore>;

const userStore: TUserStore = {
  state: () => ({
    user: null,
  }),

  getters: {
    [THIS_USER]: (state) => state.user,
  },

  mutations: {
    [SET_USER](state, user) {
      state.user = user;
    },
  },

  actions: {
    async [GET_USER]({ commit }, id) {
      const { data } = await users.show(id);
      commit(SET_USER, data);
    },
    async [INIT_USER]({ commit, dispatch }, user) {
      commit(SET_USER, user);
      await dispatch(GET_USER, user.id);
    },
  },
};

export default userStore;
