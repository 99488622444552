import type { Module } from 'vuex';
import type {
  IAddress,
  ICreateAddressBody,
  IUpdateAddressBody,
} from '@contimo/api/src/api/address';
import type { TApiPaginationQuery, TPaginationMeta } from '@contimo/types/src/Api';
import { addresses } from '@contimo/api/src/api';
import { removeFromArrayByKey, updateOrPushInArray } from '@/utils/store';
import type { TRootStore } from '@/store';
import {
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  CREATE_ADDRESS,
  GET_ADDRESSES,
  GET_ADDRESS,
} from '../actionTypes';
import {
  SET_ADDRESS,
  SET_ADDRESSES,
  REMOVE_ADDRESS,
  SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY,
  SET_ADDRESS_CREATION_SELECTED_DELIVERY_ADDRESS_ID,
  SET_ADDRESS_CREATION_DELIVERY_ADDRESS,
  SET_ADDRESS_CREATION_BILLING_ADDRESS,
  SET_ADDRESS_CREATION_SELECTED_BILLING_ADDRESS_ID,
} from '../mutationTypes';
import { ADDRESS_CREATION, ALL_ADDRESSES, ADDRESSES_LOADING } from '../gettersTypes';

interface IAddressCreation {
  billingsAddressDiffersFromDeliveryAddress: boolean;
  selectedDeliveryAddressId: number | null;
  selectedBillingAddressId: number | null;
  deliveryAddress: any | null;
  billingAddress: any | null;
}

export interface IAddressesStoreState {
  loading: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  addresses: IAddress[];
  pagination: TPaginationMeta | null;
  addressCreation: IAddressCreation;
}
type TAddressStore = Module<IAddressesStoreState, TRootStore>;

const merchantWastesStore: TAddressStore = {
  state: () => ({
    loading: false,
    createLoading: false,
    updateLoading: false,
    addresses: [],
    pagination: null,
    addressCreation: {
      billingsAddressDiffersFromDeliveryAddress: false,
      selectedDeliveryAddressId: null,
      selectedBillingAddressId: null,
      deliveryAddress: {},
      billingAddress: {},
    },
  }),

  getters: {
    [ALL_ADDRESSES]: (state) => {
      return state.addresses;
    },
    [ADDRESS_CREATION]: (state) => {
      return state.addressCreation;
    },
    [ADDRESSES_LOADING]: (state) => {
      return state.loading;
    },
  },

  mutations: {
    [SET_ADDRESS](state, data: IAddress) {
      updateOrPushInArray(state.addresses, data);
    },
    [SET_ADDRESSES](state, data: IAddress[]) {
      data.forEach((value) => {
        updateOrPushInArray(state.addresses, value);
      });
    },
    [REMOVE_ADDRESS](state, id: number) {
      removeFromArrayByKey(state.addresses, id);
    },
    setAddressesLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setCreateLoading(state, loading: boolean) {
      state.createLoading = loading;
    },
    setUpdateLoading(state, loading: boolean) {
      state.updateLoading = loading;
    },
    setAddressesPagination(state, pagination: TPaginationMeta | null) {
      state.pagination = pagination;
    },
    [SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY](state, value: boolean) {
      state.addressCreation.billingsAddressDiffersFromDeliveryAddress = value;
    },
    [SET_ADDRESS_CREATION_SELECTED_DELIVERY_ADDRESS_ID](state, addressId: number) {
      state.addressCreation.selectedDeliveryAddressId = addressId;
    },
    [SET_ADDRESS_CREATION_SELECTED_BILLING_ADDRESS_ID](state, addressId: number) {
      state.addressCreation.selectedBillingAddressId = addressId;
    },
    [SET_ADDRESS_CREATION_DELIVERY_ADDRESS](state, address: any) {
      address.type = 'delivery';
      state.addressCreation.deliveryAddress = address;
    },
    [SET_ADDRESS_CREATION_BILLING_ADDRESS](state, address: any) {
      address.type = 'billing';
      state.addressCreation.billingAddress = address;
    },
  },

  actions: {
    async [GET_ADDRESSES]({ commit }, pagination: TApiPaginationQuery = {}) {
      commit('setAddressesLoading', true);
      try {
        const { data } = await addresses.index(pagination);
        commit(SET_ADDRESSES, data.data);
        commit('setAddressesPagination', data.meta, {
          root: false,
        });
      } finally {
        commit('setAddressesLoading', false);
      }
    },
    async [GET_ADDRESS]({ commit }, id: number) {
      commit('setAddressesLoading', true);
      try {
        const { data } = await addresses.get(id);
        commit(SET_ADDRESSES, [data]);
      } finally {
        commit('setAddressesLoading', false);
      }
    },
    async [CREATE_ADDRESS]({ commit }, body: ICreateAddressBody) {
      commit('setCreateLoading', true);
      try {
        const { data } = await addresses.store(body);
        commit(SET_ADDRESS, data);
        return data;
      } finally {
        commit('setCreateLoading', false);
      }
    },
    async [UPDATE_ADDRESS]({ commit }, { id, body }: { id: number; body: IUpdateAddressBody }) {
      commit('setUpdateLoading', true);
      try {
        const { data } = await addresses.update(id, body);
        commit(SET_ADDRESS, data);

        return data;
      } finally {
        commit('setUpdateLoading', false);
      }
    },
    async [DELETE_ADDRESS]({ commit }, id: number) {
      commit('setUpdateLoading', true);
      try {
        const { data } = await addresses.remove(id);
        commit(REMOVE_ADDRESS, data.id);
        return data;
      } finally {
        commit('setUpdateLoading', false);
      }
    },
  },
};

export default merchantWastesStore;
