<template>
  <div>
    <div class="w-full flex justify-center">
      <ShopCertificates />
    </div>
    <div class="mb-3 text-center">
      <CLink
        v-for="link in footerLinks"
        :key="link.href"
        :href="link.href"
        class="text-gray-500 inline-flex mx-2"
        small
      >
        {{ link.label }}
      </CLink>
    </div>
  </div>
</template>

<script>
import ShopCertificates from '../Home/ShopCertificates/index.vue';

export default {
  components: {
    ShopCertificates,
  },
  computed: {
    footerLinks() {
      return [
        { href: '/p/agb', label: this.$t('terms') },
        { href: '/p/widerruf', label: this.$t('cancellationPolicy') },
        { href: '/p/datenschutz', label: this.$t('privacy') },
        { href: '/p/impressum', label: this.$t('imprint') },
      ];
    },
  },
};
</script>
