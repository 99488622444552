import type { Module } from 'vuex';
import { IMerchant } from '@contimo/types/src/ApiModels';
import type { TShop } from '@contimo/api/src/api/shops';
import type { IDeliveryArea } from '@contimo/api/src/api/deliveryAreas';
import type { TMerchantWaste } from '@contimo/api/src/api/merchantWastes';
import type { TPage } from '@contimo/api/src/api/pages';
import type { IOrderCreateBody } from '@contimo/api/src/api/orders';
import { storefront } from '@contimo/api/src/api';
import type {
  IContainerResponse,
  ISelectContainerQueryParams,
  TPublicHoliday,
} from '@contimo/api/src/api/storefront';
import orders from '@contimo/api/src/api/orders';
import { IAddress, ICreateAddressBody } from '@contimo/api/src/api/address';
import { IShopCertificate } from '@contimo/api/src/api/shopCertificates';
import type { TRootStore } from '@/store';
import {
  DELIVERY_AREAS,
  MERCHANT,
  MERCHANT_WASTES,
  SHOP,
  PAGES,
  SELECTED_MERCHANT_WASTE,
  SELECTED_CONTAINER,
  STOREFRONT_HOME_LOADING,
  STOREFRONT_CONTAINER_SELECTION_LOADING,
  STOREFRONT_CALCULATE_PRICE_LOADING,
  SELECTED_DELIVERY_AREA,
  MATCHTING_CONTAINERS,
  PUBLIC_HOLIDAYS,
  DELIVERY_DATE,
  PICKUP_DATE,
  SELECTED_DELIVERY_ADDRESS,
  SELECTED_BILLING_ADDRESS,
  SELECTED_DELIVERY_ADDRESS_FULL,
  SHOP_CERTIFICATES,
  CUSTOMER_NOTE,
  ANONYMOUS_ORDER,
} from '../gettersTypes';
import {
  SET_BILLDING_ADDRESS,
  SET_CUSTOMER_NOTE,
  SET_DELIVERY_ADDRESS,
  SET_DELIVERY_AREAS,
  SET_DELIVERY_DATE,
  SET_MATCHING_CONTAINERS,
  SET_MERCHANT,
  SET_MERCHANT_WASTES,
  SET_PAGES,
  SET_PICKUP_DATE,
  SET_PRICE_CALCULATION,
  SET_PUBLIC_HOLIDAYS,
  SET_SELECTED_CONTAINER,
  SET_SELECTED_DELIVERY_AREA,
  SET_SELECTED_MERCHANT_WASTE,
  SET_SHOP,
  SET_SHOP_CERTIFICATES,
  SET_TITLE,
  SET_ANONYMOUS_ORDER,
} from '../mutationTypes';
import { CREATE_ORDER, GET_CONTAINER_SELECTION, GET_STOREFRONT_HOME } from '../actionTypes';

export interface IAnonymousOrderUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  termsVersion: string;
}

export interface IAnonymousOrder {
  user: IAnonymousOrderUser;
  deliveryAddress: Omit<ICreateAddressBody, 'type'>;
  billingsAddressDiffersFromDeliveryAddress: boolean;
  billingAddress: Omit<ICreateAddressBody, 'type'>;
}

export interface IStorefrontStoreState {
  countryShort: string;
  homeLoading: boolean;
  containerSelectionLoading: boolean;
  calculatePriceLoading: boolean;
  merchant: IMerchant | null;
  shop: TShop | null;
  deliveryAreas: IDeliveryArea[];
  merchantWastes: TMerchantWaste[];
  pages: TPage[];
  shopCertificates: IShopCertificate[];
  matchtingContainers: IContainerResponse[];
  publicHolidays: TPublicHoliday[];
  selectedDeliveryAreaId: number | null;
  selectedMerchantWasteId: number | null;
  selectedContainerId: number | null;
  deliveryDate: Date | null;
  pickupDate: Date | null;
  selectedDeliveryAddressId: number | null;
  selectedBillingAddressId: number | null;
  customerNote: string | null;
  createOrderLoading: boolean;
  anonymousOrder: IAnonymousOrder | null;
}

type TStorefrontStore = Module<IStorefrontStoreState, TRootStore>;

const storefrontStore: TStorefrontStore = {
  state: () => ({
    countryShort: 'DE',
    homeLoading: false,
    containerSelectionLoading: false,
    calculatePriceLoading: false,
    merchant: null,
    shop: null,
    deliveryAreas: [],
    merchantWastes: [],
    pages: [],
    shopCertificates: [],
    matchtingContainers: [],
    publicHolidays: [],
    selectedDeliveryAreaId: null,
    selectedMerchantWasteId: null,
    selectedContainerId: null,
    deliveryDate: null,
    pickupDate: null,
    selectedDeliveryAddressId: null,
    selectedBillingAddressId: null,
    customerNote: null,
    createOrderLoading: false,
    anonymousOrder: null,
  }),

  getters: {
    [MERCHANT]: (state) => state.merchant,
    [SHOP]: (state) => state.shop,
    [DELIVERY_AREAS]: (state) => state.deliveryAreas,
    [MERCHANT_WASTES]: (state) => state.merchantWastes,
    [PAGES]: (state) => state.pages,
    [SHOP_CERTIFICATES]: (state) => state.shopCertificates,
    [MATCHTING_CONTAINERS]: (state) => state.matchtingContainers,
    [PUBLIC_HOLIDAYS]: (state) => state.publicHolidays,
    [SELECTED_DELIVERY_AREA]: (state) =>
      state.deliveryAreas.find((deliveryArea) => deliveryArea.id === state.selectedDeliveryAreaId),
    [SELECTED_MERCHANT_WASTE]: (state) =>
      state.merchantWastes.find(
        (merchantWaste) => merchantWaste.id === state.selectedMerchantWasteId,
      ),
    [SELECTED_CONTAINER]: (state) =>
      state.matchtingContainers.find((container) => container.id === state.selectedContainerId) ||
      state.matchtingContainers[0],
    [DELIVERY_DATE]: (state) => state.deliveryDate,
    [PICKUP_DATE]: (state) => state.pickupDate,
    [SELECTED_DELIVERY_ADDRESS]: (state) => state.selectedDeliveryAddressId,
    [SELECTED_BILLING_ADDRESS]: (state) => state.selectedBillingAddressId,
    [STOREFRONT_HOME_LOADING]: (state) => state.homeLoading,
    [STOREFRONT_CONTAINER_SELECTION_LOADING]: (state) => state.containerSelectionLoading,
    [STOREFRONT_CALCULATE_PRICE_LOADING]: (state) => state.calculatePriceLoading,
    [SELECTED_DELIVERY_ADDRESS_FULL]: (state, getters, rootState) =>
      rootState.addresses.addresses.find(
        (address: IAddress) => address.id === getters.selectedDeliveryAddress,
      ),
    [CUSTOMER_NOTE]: (state) => state.customerNote,
    [ANONYMOUS_ORDER]: (state) => state.anonymousOrder,
  },

  mutations: {
    [SET_MERCHANT](state, merchant: IMerchant) {
      state.merchant = merchant;
    },
    [SET_SHOP](state, shop: TShop) {
      state.shop = shop;
    },
    [SET_DELIVERY_AREAS](state, deliveryAreas: IDeliveryArea[]) {
      state.deliveryAreas = deliveryAreas;
    },
    [SET_MERCHANT_WASTES](state, merchantWastes: TMerchantWaste[]) {
      state.merchantWastes = merchantWastes;
    },
    [SET_PAGES](state, pages: TPage[]) {
      state.pages = pages;
    },
    [SET_SHOP_CERTIFICATES](state, shopCertificates: IShopCertificate[]) {
      state.shopCertificates = shopCertificates;
    },
    [SET_SELECTED_DELIVERY_AREA](state, selectedDeliveryAreaId: number) {
      state.selectedDeliveryAreaId = selectedDeliveryAreaId;
    },
    [SET_SELECTED_MERCHANT_WASTE](state, selectedMerchantWasteId: number) {
      state.selectedMerchantWasteId = selectedMerchantWasteId;
    },
    [SET_MATCHING_CONTAINERS](state, matchtingContainers: IContainerResponse[]) {
      state.matchtingContainers = matchtingContainers;
    },
    [SET_PUBLIC_HOLIDAYS](state, publicHolidays: TPublicHoliday[]) {
      state.publicHolidays = publicHolidays;
    },
    [SET_SELECTED_CONTAINER](state, selectedContainerId: number) {
      state.selectedContainerId = selectedContainerId;
    },
    [SET_DELIVERY_DATE](state, deliveryDate: Date) {
      state.deliveryDate = deliveryDate;
    },
    [SET_PICKUP_DATE](state, pickupDate: Date) {
      state.pickupDate = pickupDate;
    },
    [SET_DELIVERY_ADDRESS](state, deliveryAddressId: number) {
      state.selectedDeliveryAddressId = deliveryAddressId;
    },
    [SET_BILLDING_ADDRESS](state, billingAddressId: number) {
      state.selectedBillingAddressId = billingAddressId;
    },
    [SET_TITLE](state, title: string) {
      document.title = title;
    },
    [SET_CUSTOMER_NOTE](state, note: string) {
      state.customerNote = note;
    },
    [SET_ANONYMOUS_ORDER](state, anonymousOrder: IAnonymousOrder | null) {
      state.anonymousOrder = anonymousOrder;
    },
  },

  actions: {
    async [GET_STOREFRONT_HOME]({ commit, state }) {
      state.homeLoading = true;
      try {
        const { data } = await storefront.home();
        commit(SET_MERCHANT, data.merchant);
        commit(SET_SHOP, data.shop);
        commit(SET_DELIVERY_AREAS, data.deliveryAreas);
        commit(SET_MERCHANT_WASTES, data.merchantWastes);
        commit(SET_PAGES, data.pages);
        commit(SET_TITLE, data.merchant.name);
        commit(SET_SHOP_CERTIFICATES, data.shopCertificates);
        return data;
      } finally {
        state.homeLoading = false;
      }
    },

    async [GET_CONTAINER_SELECTION]({ commit, state }) {
      if (state.selectedMerchantWasteId && state.selectedDeliveryAreaId) {
        state.containerSelectionLoading = true;
        try {
          const body: ISelectContainerQueryParams = {
            merchantWasteId: state.selectedMerchantWasteId,
            deliveryAreaId: state.selectedDeliveryAreaId,
          };

          if (state.deliveryDate && state.pickupDate) {
            body.deliveryDateIsoString = state.deliveryDate.toISOString();
            body.pickupDateIsoString = state.pickupDate.toISOString();
          }

          if (state.selectedDeliveryAddressId) {
            body.deliveryAddressId = state.selectedDeliveryAddressId;
          } else if (
            state.anonymousOrder?.deliveryAddress &&
            state.anonymousOrder?.deliveryAddress.street &&
            state.anonymousOrder?.deliveryAddress.number &&
            state.anonymousOrder?.deliveryAddress.postalCodeId
          ) {
            body.deliveryAddress = JSON.stringify(state.anonymousOrder?.deliveryAddress);
          }

          const { data } = await storefront.selectContainer(body);
          commit(SET_MATCHING_CONTAINERS, data.matchingContainers);
          commit(SET_PUBLIC_HOLIDAYS, data.holidays);

          return data;
        } finally {
          state.containerSelectionLoading = false;
        }
      }
      return null;
    },

    async [CREATE_ORDER]({ commit, state }, body: IOrderCreateBody) {
      state.createOrderLoading = true;
      try {
        const { data } = await orders.store(body);
        commit(SET_PRICE_CALCULATION, data);
        return data;
      } finally {
        state.createOrderLoading = false;
      }
    },
  },
};

export default storefrontStore;
