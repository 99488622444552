<template>
  <div>
    <CHeader />
    <Portal to="overlays">
      <CErrorNotification v-model="showErrorNotification" />
    </Portal>
    <PortalTarget name="background-image" multiple />
    <CContainer>
      <slot />
    </CContainer>
    <CFooter />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CHeader from '@/ui/components/Header/index.vue';
import CFooter from '@/ui/components/Footer/index.vue';
import { SELECTED_CONTAINER, ERROR_NOTIFICATION } from '@/store/gettersTypes';
import { SET_ERROR_NOTIFICATION } from '@/store/mutationTypes';

export default {
  components: {
    CHeader,
    CFooter,
  },

  computed: {
    ...mapGetters([SELECTED_CONTAINER, ERROR_NOTIFICATION]),
    showErrorNotification: {
      get() {
        return this.errorNotification.show;
      },
      set(value) {
        return this.setErrorNotification({
          ...this.errorNotification,
          show: value,
        });
      },
    },
  },
  methods: {
    ...mapMutations([SET_ERROR_NOTIFICATION]),
  },
};
</script>