<template>
  <div class="py-6 flex">
    <a
      v-for="shopCertificate in shopCertificates"
      :key="shopCertificate.id"
      target="_blank"
      :href="file"
    >
      <img
        :src="getImageFile(shopCertificate)"
        class="px-3 w-auto h-40 object-contain"
      >
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHOP_CERTIFICATES } from '@/store/gettersTypes';
import { getUploadAssetUrl } from '@/utils/assets';

export default {
  computed: {
    ...mapGetters([SHOP_CERTIFICATES]),
  },

  methods: {
    file() {
      return getUploadAssetUrl(this.shopCertificate.file);
    },
    getImageFile(shopCertificate) {
      if (shopCertificate.badge) return getUploadAssetUrl(shopCertificate.badge);
      if (shopCertificate.file.includes('.pdf')) return getUploadAssetUrl('/pdf-placeholder.png');
      return getUploadAssetUrl(shopCertificate.file);
    },
  },
};
</script>
