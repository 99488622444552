export const SET_MERCHANT = 'setMerchant';
export const SET_SHOP = 'setShop';
export const SET_DELIVERY_AREAS = 'setDeliveryAreas';
export const SET_MERCHANT_WASTES = 'setMerchantWastes';
export const SET_PAGES = 'setPages';
export const SET_SHOP_CERTIFICATES = 'setShopCertificates';
export const SET_SELECTED_DELIVERY_AREA = 'setSelectedDeliveryArea';
export const SET_SELECTED_MERCHANT_WASTE = 'setSelectedMerchantWaste';
export const SET_MATCHING_CONTAINERS = 'setMatchingContainers';
export const SET_PUBLIC_HOLIDAYS = 'setPublicHolidays';
export const SET_SELECTED_CONTAINER = 'setSelectedContainer';
export const SET_PRICE_CALCULATION = 'setPriceCalculation';
export const SET_DELIVERY_DATE = 'setDeliveryDate';
export const SET_PICKUP_DATE = 'setPickupDate';
export const SET_DELIVERY_ADDRESS = 'setDeliveryAddress';
export const SET_BILLDING_ADDRESS = 'setBillingAddress';
export const SET_CUSTOMER_NOTE = 'setCustomerNote';
export const SET_TITLE = 'setTitle';

export const SET_USER = 'setUser';

export const SET_NEXT_ROUTE = 'setNextRoute';
export const SET_TOKEN = 'setToken';
export const SET_AUTH_LOADING = 'setAuthLoading';
export const SET_AUTH_READY = 'setAuthReady';
export const SET_LOGIN_LOADING = 'setLoginLoading';

export const SET_ADDRESSES = 'setAddresses';
export const SET_ADDRESS = 'setAddress';
export const REMOVE_ADDRESS = 'removeAddress';

export const SET_POSTAL_CODE_RESULTS = 'setPostalCodeResults';
export const RESET_POSTAL_CODE_SEARCH = 'resetPostalCodeSearch';

export const SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY =
  'setAddressCreationBillingDiffersFromDelivery';
export const SET_ADDRESS_CREATION_SELECTED_DELIVERY_ADDRESS_ID =
  'setAddressCreationSelectedDeliveryAddressId';
export const SET_ADDRESS_CREATION_SELECTED_BILLING_ADDRESS_ID =
  'setAddressCreationSelectedBillingAddressId';
export const SET_ADDRESS_CREATION_DELIVERY_ADDRESS = 'setAddressCreationDeliveryAddress';
export const SET_ADDRESS_CREATION_BILLING_ADDRESS = 'setAddressCreationBillingAddress';

export const SET_ORDERS = 'setOrders';
export const SET_ORDER = 'setOrder';
export const SET_ORDERS_LOADING = 'setOrdersLoading';

export const SET_CANCELING_ORDERS = 'setCancelingOrders';
export const SET_LAST_CANCELED_ORDER = 'setLastCanceledOrder';
export const SET_USE_LAST_CANCELED_ORDER = 'setaLastCanceledOrders';

export const SET_SUCCESS_NOTIFICATION = 'setSuccessNotification';
export const SET_ERROR_NOTIFICATION = 'setErrorNotification';

export const SET_ANONYMOUS_ORDER = 'setAnonymousOrder';
