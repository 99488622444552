import type { Module } from 'vuex';
import type { TPaginationMeta } from '@contimo/types/src/Api';
import { postalCodes } from '@contimo/api/src/api';
import { TPostalCode } from '@contimo/api/src/api/postalCodes';
import type { TRootStore } from '@/store';
import { CURRENT_POSTAL_CODE_RESULTS } from '../gettersTypes';
import { SET_POSTAL_CODE_RESULTS, RESET_POSTAL_CODE_SEARCH } from '../mutationTypes';
import { SEARCH_POSTAL_CODES } from '../actionTypes';

export interface IPostalCodesStoreState {
  searchParam: string;
  searchResults: TPostalCode[];
  pagination: TPaginationMeta | null;
}

type TPostalCodesStore = Module<IPostalCodesStoreState, TRootStore>;

const postalCodesStore: TPostalCodesStore = {
  state: () => ({
    searchParam: '',
    searchResults: [],
    pagination: null,
  }),

  getters: {
    [CURRENT_POSTAL_CODE_RESULTS]: (state) => state.searchResults,
  },

  mutations: {
    [SET_POSTAL_CODE_RESULTS](state, results: TPostalCode[]) {
      state.searchResults = results;
    },
    [RESET_POSTAL_CODE_SEARCH](state) {
      state.searchResults = [];
    },
    setPostalCodesPagination(state, pagination: TPaginationMeta) {
      state.pagination = pagination;
    },
  },

  actions: {
    async [SEARCH_POSTAL_CODES]({ commit }, search: string) {
      const { data } = await postalCodes.search(search, { page: 1, limit: 50 });
      commit(SET_POSTAL_CODE_RESULTS, data.data);
      commit('setPostalCodesPagination', data.meta, {
        root: false,
      });
      return data;
    },
  },
};

export default postalCodesStore;
