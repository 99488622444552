<template>
  <CContainer class="w-full min-h-stretch bg-white xl:border-l xl:border-r" no-spacing>
    <component :is="currentLayout" v-if="currentLayout">
      <router-view v-if="!isAuthLoading && !storefrontHomeLoading && !cancelingOrders" />
    </component>
    <PortalTarget name="overlays" multiple />
    <transition
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="!showApp || isAuthLoading || storefrontHomeLoading || cancelingOrders"
        class="fixed top-0 left-0 w-full min-h-stretch max-h-screen
        flex justify-center items-center transition-opacity duration-300
        ease-in-out bg-white z-40"
      >
        <CLoading />
      </div>
    </transition>
  </CContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_STOREFRONT_HOME } from './store/actionTypes';
import {
  AUTH_IS_READY,
  CANCELING_ORDERS,
  IS_AUTH_LOADING,
  SHOP,
  STOREFRONT_HOME_LOADING,
} from './store/gettersTypes';

export default {
  data() {
    return {
      showApp: false,
    };
  },

  computed: {
    ...mapGetters([
      STOREFRONT_HOME_LOADING,
      IS_AUTH_LOADING,
      AUTH_IS_READY,
      SHOP,
      CANCELING_ORDERS,
    ]),
    currentLayout() {
      if (this.$route?.meta?.layout) {
        return `${this.$route?.meta?.layout}Layout`;
      }
      return false;
    },
  },

  watch: {
    authIsReady: {
      immediate: true,
      handler() {
        this.showApp = this.authIsReady;
      },
    },
  },

  created() {
    this.getStorefrontHome().then(() => {
      document.documentElement.style.setProperty('--primary', this.shop.bgColor);
      document.documentElement.style.setProperty('--on-primary', this.shop.textColor);
    });
  },

  methods: {
    ...mapActions([GET_STOREFRONT_HOME]),
  },
};
</script>
