import { IContainerRaw, IMerchant } from '@contimo/types/src/ApiModels';
import { AxiosPromise } from 'axios';
import { storefrontClient as client } from './client';
import { IDeliveryArea } from './deliveryAreas';
import { TMerchantWaste } from './merchantWastes';
import { TPage } from './pages';
import { IShopCertificate } from './shopCertificates';
import { TShop } from './shops';

export type THome = {
  merchant: IMerchant;
  shop: TShop & {
    publicPlacementNotes: string;
    generalPlacementNotes: string;
  };
  deliveryAreas: IDeliveryArea[];
  merchantWastes: TMerchantWaste[];
  pages: TPage[];
  shopCertificates: IShopCertificate[];
};

export interface IPriceCalculationCreateBodyRaw {
  productId: number;
  merchantWastePriceId: number;
  deliveryDate: Date;
  pickupDate: Date;
}
export interface TPriceCalculationCreateBodyWithPostalCode extends IPriceCalculationCreateBodyRaw {
  postalCodeId: number;
}

export interface TPriceCalculationCreateBodyWithAddress extends IPriceCalculationCreateBodyRaw {
  addressId: number;
}

export type IPriceCalculationCreateBody =
  | TPriceCalculationCreateBodyWithPostalCode
  | TPriceCalculationCreateBodyWithAddress;

export type TPriceCalculation = {
  deliveryAdditionalPrice: number;
  deliveryDistance: number;
  deliveryPrice: number;
  wastePrice: number;
  basePrice: number;
  durationPrice: number;
  totalPrice: number;
};

export type TPublicHoliday = {
  id: number;
  date: Date;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

export interface IContainerResponse extends IContainerRaw {
  priceCalculation: TPriceCalculation;
}

export type TSelectContainerResponse = {
  matchingContainers: IContainerResponse[];
  holidays: TPublicHoliday[];
};

export interface ISelectContainerQueryParams {
  merchantWasteId: number;
  deliveryAreaId: number;
  deliveryDateIsoString?: string;
  pickupDateIsoString?: string;
  deliveryAddressId?: number;
  deliveryAddress?: string;
}

export default {
  home: (): AxiosPromise<THome> => {
    return client.get('/storefront/home');
  },
  selectContainer: ({
    merchantWasteId,
    deliveryAreaId,
    deliveryDateIsoString,
    pickupDateIsoString,
    deliveryAddressId,
    deliveryAddress,
  }: ISelectContainerQueryParams): AxiosPromise<TSelectContainerResponse> => {
    return client.get(`/storefront/select-container`, {
      params: {
        merchantWasteId,
        deliveryAreaId,
        deliveryAddressId,
        deliveryDate: deliveryDateIsoString,
        pickupDate: pickupDateIsoString,
        deliveryAddress,
      },
    });
  },
  getPageByUid: (uid: string) => {
    return client.get(`/storefront/page/${uid}`);
  },
};
