import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import { SET_SUCCESS_NOTIFICATION, SET_ERROR_NOTIFICATION } from '../mutationTypes';
import { SUCCESS_NOTIFICATION, ERROR_NOTIFICATION } from '../gettersTypes';

export interface IUiStoreState {
  successNotification: {
    show: boolean;
    content: string | null;
  };
  errorNotification: {
    show: boolean;
    content: string | null;
  };
}

type TUiStore = Module<IUiStoreState, TRootStore>;

const uiStore: TUiStore = {
  state: () => ({
    successNotification: {
      show: false,
      content: null,
    },
    errorNotification: {
      show: false,
      content: null,
    },
  }),

  getters: {
    [SUCCESS_NOTIFICATION]: (state) => state.successNotification,
    [ERROR_NOTIFICATION]: (state) => state.errorNotification,
  },

  mutations: {
    [SET_SUCCESS_NOTIFICATION](
      state,
      { show, content }: { show: boolean; content: string | null },
    ) {
      state.successNotification = { show, content };
    },
    [SET_ERROR_NOTIFICATION](state, { show, content }: { show: boolean; content: string | null }) {
      state.errorNotification = { show, content };
    },
  },
};

export default uiStore;
