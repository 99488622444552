import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import store from '../store';
import { SET_NEXT_ROUTE } from '../store/mutationTypes';
import { INIT_AUTH } from '../store/actionTypes';

const originalPush = VueRouter.prototype.push;
// @ts-ignore
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

const canUserAccess = async (to: Route) => {
  const setNextRoute = (nextRoute: string) => store.commit(SET_NEXT_ROUTE, nextRoute);
  const initAuth = async () => store.dispatch(INIT_AUTH, to.fullPath);
  const { authIsReady } = store.getters;

  const run = () => {
    const { accessToken } = store.getters;
    const defaultRoute = '/';
    if (!to.meta?.public && !accessToken) {
      setNextRoute(to.fullPath);
      return {
        canAccess: false,
        defaultRoute,
      };
    }

    return {
      canAccess: true,
      defaultRoute,
    };
  };
  if (!authIsReady) {
    await initAuth();
    return run();
  }
  return run();
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../ui/views/Home.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/select-container',
    name: 'Select Conainer',
    component: () =>
      import(/* webpackChunkName: "selectContainer" */ '../ui/views/SelectContainer.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../ui/views/Login.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import(/* webpackChunkName: "address" */ '../ui/views/Address.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "order" */ '../ui/views/Order.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/failed-order',
    name: 'Failed Order',
    component: () => import(/* webpackChunkName: "failedOrder" */ '../ui/views/FailedOrder.vue'),
    meta: {
      public: false,
      layout: 'App',
    },
  },
  {
    path: '/successful-order',
    name: 'Successful Order',
    component: () =>
      import(/* webpackChunkName: "successfulOrder" */ '../ui/views/SuccessfulOrder.vue'),
    meta: {
      public: false,
      layout: 'App',
    },
  },
  {
    path: '/p/:slug',
    name: 'Page',
    component: () => import(/* webpackChunkName: "page" */ '../ui/views/Page.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../ui/views/Account.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/request-password-reset',
    component: () =>
      import(/* webpackChunkName: "login" */ '../ui/views/auth/RequestPasswordReset.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
  {
    path: '/reset-password/:email',
    component: () => import(/* webpackChunkName: "login" */ '../ui/views/auth/ResetPassword.vue'),
    meta: {
      public: true,
      layout: 'App',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach(async (to, _, next) => {
  const { canAccess, defaultRoute } = await canUserAccess(to);
  if (!canAccess) {
    next(defaultRoute);
  } else {
    next();
  }
});

export default router;
