<template>
  <CContainer no-spacing>
    <div class="flex flex-col justify-between">
      <CNavBar v-if="shop && merchant" big>
        <template v-slot:title>
          <router-link to="/">
            <img
              class="w-auto h-16"
              v-if="logo"
              :src="logo">
            <CTypo v-else tstyle="title3">
              {{ merchant.name }}
            </CTypo>
          </router-link>
        </template>

        <template v-slot:right>
          <div class="flex items-center">
            <a class="hidden lg:flex items-center float-left mr-10" :href="`tel:${merchant.phone}`">
              <CIcon name="phone" class="mr-1 group-hover:text-gray-500" size="32" />
              <div>
                <CTypo>{{ $t('phoneAvailable') }}</CTypo>
                <CTypo tstyle="headline" class="text-right">{{ merchant.phone }}</CTypo>
              </div>
            </a>

            <CLink to="/account" class="font-semibold">
              <template v-if="accessToken">{{ $t('myAccount') }}</template>
              <template v-else>{{ $t('login') }}</template>
            </CLink>
          </div>
        </template>
      </CNavBar>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACCESS_TOKEN, MERCHANT, SHOP } from '@/store/gettersTypes';
import { getUploadAssetUrl } from '@/utils/assets';

export default {
  computed: {
    ...mapGetters([MERCHANT, SHOP, ACCESS_TOKEN]),
    logo() {
      return this.shop && getUploadAssetUrl(this.shop.logo);
    },
  },
};
</script>
