import type { Module } from 'vuex';
import type { TApiPaginationQuery, TPaginationMeta } from '@contimo/types/src/Api';
import { orders } from '@contimo/api/src/api';
import { IOrder } from '@contimo/api/src/api/orders';
import { DateTime } from 'luxon';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import {
  SET_ORDERS,
  SET_ORDER,
  SET_ORDERS_LOADING,
  SET_CANCELING_ORDERS,
  SET_LAST_CANCELED_ORDER,
  SET_USE_LAST_CANCELED_ORDER,
} from '../mutationTypes';
import { CANCEL_MY_ORDERS, GET_ORDERS } from '../actionTypes';
import {
  ALL_ORDERS,
  CANCELING_ORDERS,
  LAST_CANCELED_ORDER,
  ORDERS_NEXT_PAGE,
  USE_LAST_CANCELED_ORDER,
} from '../gettersTypes';

export interface IOrdersStoreState {
  loading: boolean;
  orders: IOrder[];
  order: IOrder | null;
  pagination: TPaginationMeta | null;
  cancelingOrders: boolean;
  lastCanceledOrder: IOrder | null;
  useLastCanceledOrder: boolean;
}

type TOrdersStore = Module<IOrdersStoreState, TRootStore>;

const ordersStore: TOrdersStore = {
  state: () => ({
    loading: false,
    orders: [],
    order: null,
    pagination: null,
    publicPropertyPlacement: false,
    cancelingOrders: false,
    lastCanceledOrder: null,
    useLastCanceledOrder: false,
  }),

  getters: {
    [ALL_ORDERS]: (state) => state.orders,
    [ORDERS_NEXT_PAGE]: (state) => {
      return (state.pagination?.nextPageUrl && state.pagination.currentPage + 1) || 0;
    },
    [CANCELING_ORDERS]: (state) => state.cancelingOrders,
    [LAST_CANCELED_ORDER]: (state) => state.lastCanceledOrder,
    [USE_LAST_CANCELED_ORDER]: (state) => state.useLastCanceledOrder,
  },

  mutations: {
    [SET_ORDERS](state, data: IOrder[]) {
      data.forEach((value) => {
        updateOrPushInArray(state.orders, value);
      });
    },
    [SET_ORDER](state, data: IOrder) {
      updateOrPushInArray(state.orders, data);
    },
    [SET_ORDERS_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    setOrdersPagination(state, meta: TPaginationMeta) {
      state.pagination = meta;
    },
    [SET_CANCELING_ORDERS](state, value: boolean) {
      state.cancelingOrders = value;
    },
    [SET_LAST_CANCELED_ORDER](state, order: IOrder) {
      state.lastCanceledOrder = order;
    },
    [SET_USE_LAST_CANCELED_ORDER](state, value: boolean) {
      state.useLastCanceledOrder = value;
    },
  },

  actions: {
    async [GET_ORDERS]({ commit }, pagination: TApiPaginationQuery = {}) {
      commit(SET_ORDERS_LOADING, true);
      try {
        const { data } = await orders.index({
          sort: 'desc',
          ...pagination,
        });
        commit(SET_ORDERS, data.data);
        commit('setOrdersPagination', data.meta);
      } finally {
        commit(SET_ORDERS_LOADING, false);
      }
    },
    async [CANCEL_MY_ORDERS]({ commit }, to?: string) {
      commit(SET_CANCELING_ORDERS, true);
      try {
        const { data } = await orders.cancelMyOrders();
        if (data.length) {
          const lastCanceledOrder = data.reduce((a, b) =>
            DateTime.fromISO(a.updatedAt) > DateTime.fromISO(b.updatedAt) ? a : b,
          );
          commit(SET_LAST_CANCELED_ORDER, lastCanceledOrder);
          if (to?.startsWith('/order')) {
            commit(SET_USE_LAST_CANCELED_ORDER, true);
          }
        }
      } finally {
        commit(SET_CANCELING_ORDERS, false);
      }
    },
  },
};

export default ordersStore;
