export const MERCHANT = 'merchant';
export const SHOP = 'shop';
export const DELIVERY_AREAS = 'deliveryAreas';
export const MERCHANT_WASTES = 'merchantWastes';
export const PAGES = 'pages';
export const SHOP_CERTIFICATES = 'shopCertificates';
export const MATCHTING_CONTAINERS = 'matchingContainers';
export const PUBLIC_HOLIDAYS = 'publicHolidays';
export const SELECTED_MERCHANT_WASTE = 'selectedMerchantWaste';
export const SELECTED_CONTAINER = 'selectedContainer';
export const PRICE_CALCULATION = 'priceCalculation';
export const STOREFRONT_HOME_LOADING = 'storefrontHomeLoading';
export const STOREFRONT_CONTAINER_SELECTION_LOADING = 'storefrontContainerSelectionLoading';
export const STOREFRONT_CALCULATE_PRICE_LOADING = 'storefrontCalculatePriceLoading';
export const SELECTED_DELIVERY_AREA = 'selectedDeliveryArea';
export const DELIVERY_DATE = 'deliveryDate';
export const PICKUP_DATE = 'pickupDate';
export const SELECTED_DELIVERY_ADDRESS = 'selectedDeliveryAddress';
export const SELECTED_BILLING_ADDRESS = 'selectedBillingAddress';
export const SELECTED_DELIVERY_ADDRESS_FULL = 'selectedDeliveryAddressFull';
export const CUSTOMER_NOTE = 'customerNote';

export const THIS_USER = 'thisUser';

export const IS_AUTH_LOADING = 'isAuthLoading';
export const IS_LOGIN_LOADING = 'isLoginLoading';
export const HAS_LOGIN_ERROR = 'hasLoginError';
export const AUTH_IS_READY = 'authIsReady';
export const ROUTE_AFTER_LOGIN = 'routeAfterLogin';
export const ACCESS_TOKEN = 'accessToken';

export const ALL_ADDRESSES = 'allAddresses';
export const ADDRESSES_LOADING = 'addressesLoading';

export const CURRENT_POSTAL_CODE_RESULTS = 'currentPostalCodeResults';

export const ADDRESS_CREATION = 'addressCreation';

export const ALL_ORDERS = 'allOrders';
export const CURRENT_ORDER = 'currentOrder';
export const ORDERS_NEXT_PAGE = 'ordersNextPage';

export const CANCELING_ORDERS = 'cancelingOrders';
export const LAST_CANCELED_ORDER = 'lastCanceledOrder';
export const USE_LAST_CANCELED_ORDER = 'useLastCanceledOrder';

export const SUCCESS_NOTIFICATION = 'successNotification';
export const ERROR_NOTIFICATION = 'errorNotification';

export const ANONYMOUS_ORDER = 'anonymousOrder';
